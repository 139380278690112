import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";
// import {
//   Box,
//   Image,
// } from "@chakra-ui/react";
import Layout2  from "../components/layout-2"
import ContactDrawer from "../components/atoms/contactDrawer"
import MenuDrawer from "../components/atoms/menuDrawer"
import EmblaCarousel from '../components/EmblaCarousel'

const SLIDE_COUNT = 10;
const slides = Array.from(Array(SLIDE_COUNT).keys());

function Index2() {
//   const data = useStaticQuery(graphql`
//   query IndexImages2 {
//     A: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "ingatlan" } }) {
//       edges {
//         node {
//           secure_url
//           tags
//         }
//       }
//     }
//     B: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "enterior" } }) {
//       edges {
//         node {
//           secure_url
//           tags
//         }
//       }
//     }
//     C: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "epulet" } }) {
//       edges {
//         node {
//           secure_url
//           tags
//         }
//       }
//     }
//     D: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "varoskep" } }) {
//       edges {
//         node {
//           secure_url
//           tags
//         }
//       }
//     }
//   }
// `);

// const clImagesA = data.A.edges;
// const clImagesB = data.B.edges;
// const clImagesC = data.C.edges;
// const clImagesD = data.D.edges;

  return(
    <Layout2>
      <SEO
        keywords={[`épület`, `enteriőr`, `ingatlan`, `iroda`, `lakás`, `építészetií`, `fotó`, `fotós`, `fotográfus`, ]}
        title="Home"
      />
        <ContactDrawer />
        <EmblaCarousel slides={slides} />
          {/* <Box className="content">
            <EmblaCarousel>
              <Box>
                <Image style={{ height: `calc(100vh - 192px)`}} src="https://source.unsplash.com/user/dansenior" />
              </Box>
            </EmblaCarousel>
          </Box> */}
        <MenuDrawer />
    </Layout2>
  )
}

export default Index2;
